import { defineStore } from 'pinia'

interface Modal {
  id: string
  isOpen: boolean
}

interface ModalStore {
  modals: Modal[]
  courseModal: {
    slug: ''
    isOpen: boolean
    targetType: string
    targetId: string
  }
}

export const useModalStore = defineStore('modal', {
  state: () => {
    return {
      modals: [] as Array<Modal>,
      courseModal: {
        slug: '',
        isOpen: true,
        targetType: '',
        targetId: '',
      },
    } as ModalStore
  },
  actions: {
    register(modalId: string) {
      const modal = this.findModalById(modalId)
      if (!modal) {
        const modal = {
          id: modalId,
          isOpen: false,
        }
        this.modals.push(modal)
      }
    },
    openModal(modalId: string) {
      const modal = this.findModalById(modalId)
      const { $segment } = useNuxtApp()
      $segment.track('Modal Opened', {
        modal_id: modalId,
      }, {
        integrations: {
          'Facebook Pixel': false,
        },
      })
      modal.isOpen = true
    },
    closeModal(modalId: string) {
      const modal = this.findModalById(modalId)
      modal.isOpen = false
    },
  },
  getters: {
    findModalById: state => (modalId: string) => {
      return state.modals.filter(modal => modal.id === modalId)[0]
    },
    isOpen: state => (modalId: string) => {
      const modal = state.modals.filter(modal => modal.id === modalId)[0]
      if (modal) {
        return modal.isOpen
      }
      else {
        return false
      }
    },
  },
})
